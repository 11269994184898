const localHosts = ["localhost"];
const productionHosts = ["novomedlink.com", "www.novomedlink.com"];
const testLocalAgainstQaKey = "testLocalAgainstQa";
function getShouldTestLocalAgainstQa() {
    return localStorage.getItem(testLocalAgainstQaKey) === "true";
}
export function getUrl(local, qa, prod) {
    const host = window.location ? window.location.host : "";
    if (localHosts.some((x) => host.indexOf(x) >= 0) &&
        !getShouldTestLocalAgainstQa()) {
        return local;
    }
    if (productionHosts.some((x) => host.indexOf(x) >= 0)) {
        return prod;
    }
    return qa;
}
const ENV_LOCAL = "local";
const ENV_QA = "qa";
const ENV_PRODUCTION = "prod";
export function getEnvironment() {
    const host = window.location ? window.location.host : "";
    if (localHosts.some((x) => host.indexOf(x) >= 0) &&
        !getShouldTestLocalAgainstQa()) {
        return ENV_LOCAL;
    }
    if (productionHosts.some((x) => host.indexOf(x) >= 0)) {
        return ENV_PRODUCTION;
    }
    return ENV_QA;
}
export function isAuthorEnvironment() {
    return window.location.port === "4502";
}
export function getAccountSiteBaseUrl() {
    return getUrl("http://vmware:9005/", "https://account.novomedlink.nnittest.com/", "https://account.novomedlink.com/");
}
export function getAccountApiBaseUrl() {
    return getUrl("http://vmware:9005/api/", "https://account.novomedlink.nnittest.com/api/", "https://account.novomedlink.com/api/");
}
export function getApiBaseUrl() {
    return getUrl("http://vmware:9006/api/", "https://api.novomedlink.nnittest.com/api/", "https://api.novomedlink.com/api/");
}
export function getPatientApiBaseUrl() {
    return getUrl("http://vmware:9007/api/", "https://api-nnipatient.nnittest.com/api/", "https://api.nnipatient.com/api/");
}
