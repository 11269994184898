/** @jsx createElement */
import { createElement } from "common/jsx-factory";
export function NoReps(ErrorPhone) {
    return (createElement("div", { className: "no-reps" },
        createElement("div", { className: "cmp-text" },
            createElement("h3", { className: "nni-hcp--text--headline1" }, "We\u2019re sorry."),
            createElement("p", { className: "nni-hcp--text--body-xl" },
                "There do not appear to be any representatives in your area.",
                " ",
                ErrorPhone && (createElement("span", null,
                    "Please call ",
                    createElement("strong", null, ErrorPhone),
                    " for further assistance."))))));
}
