/** @jsx createElement */
import { createElement } from "common/jsx-factory";
export function ZipHeadingForm({ Zip, onSubmit }) {
    let value = Zip;
    const onChange = (e) => {
        value = e.target.value;
    };
    return (createElement("form", { className: "zip-heading-form cmp-text", onSubmit: (e) => {
            e.preventDefault();
            onSubmit(value);
        } },
        createElement("p", { className: "nni-hcp--text--body-xl nni-hcp--alignment--center" }, "Connect with a Novo Nordisk representative in your area:"),
        createElement("div", { className: "icon icon--connect-pin" }),
        createElement("p", { className: "nni-hcp--text--body-xl" }, "Your Zip Code:"),
        createElement("input", { type: "text", name: "Zip", value: Zip, autoFocus: true, onKeyUp: onChange }),
        createElement("button", { className: "nni-hcp--button nni-hcp--button-style--text nni-hcp--button-size--small nni-hcp--top-margin--2x" },
            createElement("div", null,
                createElement("a", null,
                    createElement("span", { className: "button-text" }, "Apply"))))));
}
