/** @jsx createElement */
import { createElement } from "common/jsx-factory";
import { disableBodyScroll, enableBodyScroll } from "body-scroll-lock";
import EventApi from "common/event-api";
import { v4 as uuidv4 } from "uuid";
export default class Modal {
    constructor(openHandlers) {
        this.parentElement = document.body;
        this.registerOpenHandlers(openHandlers);
        this.onKeyUp = this.onKeyUp.bind(this);
    }
    static get Opened() {
        return "modal--opened";
    }
    static get Opening() {
        return "modal--opening";
    }
    static get Closed() {
        return "modal--closed";
    }
    static get Closing() {
        return "modal--closing";
    }
    static get Created() {
        return "modal--created";
    }
    getClass() {
        return "modal";
    }
    isClosedOnBackgroundClick() {
        return false;
    }
    isClosedByEscape() {
        return true;
    }
    isRemovedOnClose() {
        return true;
    }
    isScrollBlocking() {
        return true;
    }
    registerOpenHandlers(handlers) {
        handlers.forEach((handler) => {
            handler instanceof HTMLElement &&
                handler.addEventListener("click", (e) => {
                    e.preventDefault();
                    this.open();
                });
        });
    }
    onClosed() {
        EventApi.trigger(Modal.Closed, this);
    }
    onClosing() {
        EventApi.trigger(Modal.Closing, this);
    }
    onCreated() {
        EventApi.trigger(Modal.Created, this);
    }
    onOpened() {
        EventApi.trigger(Modal.Opened, this);
    }
    onOpening() {
        EventApi.trigger(Modal.Opening, this);
    }
    onKeyUp(event) {
        const key = event.key || event.keyCode;
        if (key === "Escape" || key === "Esc" || key === 27) {
            if (this.isClosedByEscape()) {
                this.close();
            }
        }
    }
    createModalIfNecessary() {
        if (!this.rootElement) {
            this.rootElement = this.render();
            this.parentElement.appendChild(this.rootElement);
            this.onCreated();
        }
    }
    open() {
        this.onOpening();
        this.createModalIfNecessary();
        this.rootElement.style.display = "";
        if (this.isScrollBlocking()) {
            disableBodyScroll(this.rootElement);
        }
        document.addEventListener("keyup", this.onKeyUp);
        this.onOpened();
    }
    close() {
        if (this.rootElement) {
            this.onClosing();
            if (this.isScrollBlocking()) {
                enableBodyScroll(this.rootElement);
            }
            if (this.isRemovedOnClose() && this.rootElement.parentNode) {
                this.rootElement.parentNode.removeChild(this.rootElement);
                delete this.rootElement;
            }
            else {
                this.rootElement.style.display = "none";
            }
            document.removeEventListener("keyup", this.onKeyUp);
            this.onClosed();
        }
    }
    render() {
        this.content = this.renderCore();
        this.content.addEventListener("click", (e) => e.stopPropagation());
        return (createElement("div", { id: this.getId(), className: this.getClass(), onClick: (e) => {
                e.stopPropagation();
                if (this.isClosedOnBackgroundClick()) {
                    e.preventDefault();
                    this.close();
                }
            } }, this.content));
    }
}
export class AttachedModal extends Modal {
    constructor(rootElement, openHandlers) {
        super(openHandlers);
        if (rootElement.id !== this.getId()) {
            throw "Attached modal to invalid element!";
        }
        this.rootElement = rootElement;
    }
    isRemovedOnClose() {
        return false;
    }
    createModalIfNecessary() {
        // never necessary for attached modals
    }
    render() {
        // attached to an element instead
        throw "Called render() on attach modal!";
    }
    renderCore() {
        // attached to an element instead
        throw "Called renderCore() on attach modal!";
    }
}
export class SimpleModal extends Modal {
    constructor(headlineHtml, contentHtml) {
        super([]);
        this.headlineHtml = headlineHtml;
        this.contentHtml = contentHtml;
    }
    getId() {
        return `simple-modal-${uuidv4()}`;
    }
    onCancel(e) {
        e.preventDefault();
        this.close();
    }
    renderCore() {
        return (createElement("div", { className: "simple-modal container" },
            createElement("div", { className: "content-wrapper" },
                createElement("div", { className: "content" },
                    createElement("h3", { className: "nni-hcp--text--headline3", dangerouslySetInnerHTML: this.headlineHtml }),
                    createElement("p", { className: "nni-hcp--text--body-1", dangerouslySetInnerHTML: this.contentHtml }),
                    createElement("div", { className: "buttons" },
                        createElement("div", { className: "nni-hcp--button nni-hcp--button-style--default nni-hcp--button-size--small" },
                            createElement("div", null,
                                createElement("a", { href: "#", onClick: this.onCancel.bind(this) },
                                    createElement("span", { className: "button-text" }, "Continue"))))),
                    createElement("button", { className: "close", "aria-label": "Close", onClick: this.onCancel.bind(this) })))));
    }
}
export class FormularyModal extends Modal {
    constructor(contentHtml) {
        super([]);
        this.contentHtml = contentHtml;
        this.id = uuidv4();
    }
    getId() {
        return `formulary-modal-${this.id}`;
    }
    onCancel(e) {
        e.preventDefault();
        this.close();
    }
    renderCore() {
        return (createElement("div", { className: "formulary-modal simple-modal container" },
            createElement("div", { className: "content-wrapper" },
                createElement("div", { className: "content" },
                    createElement("div", { className: "nni-hcp--text--body-1" }, this.contentHtml),
                    createElement("button", { className: "close", "aria-label": "Close", onClick: this.onCancel.bind(this) })))));
    }
}
