/** @jsx createElement */
import { createElement } from "common/jsx-factory";
import { getApiBaseUrl } from "common/models/url-base";
export default function RepImage(props) {
    if (!props.rep) {
        return;
    }
    const { firstName, lastName, image, image2x } = props.rep;
    const apiBase = getApiBaseUrl();
    if (!apiBase)
        return;
    const urlBase = new URL(apiBase).origin;
    const x1 = `${urlBase}/${image}`;
    const x2 = `${urlBase}/${image2x}`;
    return (createElement("div", { className: "cmp-far__rep-image" },
        createElement("img", { src: x1, srcSet: `${x1} 1x, ${x2} 2x`, alt: `Photo of ${firstName} ${lastName} ` })));
}
