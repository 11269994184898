/** @jsx createElement */
import { createElement } from "common/jsx-factory";
export function Loading() {
    return (createElement("div", { className: "loading" },
        createElement("div", { className: "loading__icon" },
            createElement("div", null),
            createElement("div", null),
            createElement("div", null),
            createElement("div", null))));
}
