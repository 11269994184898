/** @jsx createElement */
import { createElement } from "common/jsx-factory";
import Modal from "common/modal";
import { contactRep } from "../api";
import { titleCase, validateEmail, validatePhone } from "../helpers";
import { cleanPhone } from "../helpers/formatting";
const MODAL_ID_FAR = "modal-find-a-rep";
const SELECTOR_BUTTON = ".nni-hcp--button";
const CLASS_LOADING = "loading";
export class ContactRepModal extends Modal {
    constructor(props) {
        super([]);
        this.defaultLegalText = `I understand and agree that I am providing my contact information to
                Novo Nordisk so that Novo Nordisk may contact me via email or
                telephone to discuss products, patient support services,
                promotional education, or other opportunities that may be of
                interest to me. By providing my information to Novo Nordisk
                above, I certify I am at least eighteen (18) years of age and
                consent to the use of my personal information as explained
                above.`;
        this.Rep = props.Rep;
        this.LegalText = props.LegalText;
        this.Source = props.Source;
        this.Audience = props.Audience;
        this.Zip = props.Zip;
        this.SalesForce = this.Rep.salesForce;
        this.callback = props.callback;
        this.InitialForm = props.InitialForm;
        this.validation = {
            submitted: false,
            valid: false,
            firstNameError: false,
            lastNameError: false,
            phoneError: false,
            emailError: false,
        };
    }
    getId() {
        return MODAL_ID_FAR;
    }
    validate(data) {
        let valid = true;
        let firstNameError;
        let lastNameError;
        let phoneError;
        let emailError;
        if (data.FirstName === "") {
            valid = false;
            firstNameError = true;
        }
        if (data.LastName === "") {
            valid = false;
            lastNameError = true;
        }
        if (!validateEmail(data.Email)) {
            valid = false;
            emailError = true;
        }
        if (!validatePhone(data.Phone)) {
            valid = false;
            phoneError = true;
        }
        return {
            submitted: true,
            valid,
            firstNameError,
            lastNameError,
            phoneError,
            emailError,
        };
    }
    onSubmit(e) {
        e.preventDefault();
        const form = document.querySelector(`#${MODAL_ID_FAR} form`);
        form.querySelector(SELECTOR_BUTTON).classList.add(CLASS_LOADING);
        const data = {
            FirstName: form.querySelector("[name=firstName]").value,
            LastName: form.querySelector("[name=lastName]").value,
            Email: form.querySelector("[name=email]").value,
            Phone: cleanPhone(form.querySelector("[name=phone]").value),
            Source: this.Source,
            Audience: this.Audience,
            Zip: this.Zip,
            SalesForce: this.SalesForce,
        };
        this.validation = this.validate(data);
        if (this.validation.valid) {
            contactRep(data).then((response) => {
                this.callback(response, this.Rep);
                this.close();
            });
        }
        else {
            this.updateForm(data);
        }
    }
    onCancel(e) {
        e.preventDefault();
        this.close();
    }
    updateForm(data) {
        const container = this.form.parentElement;
        container.removeChild(this.form);
        this.form = this.renderForm(data);
        container.appendChild(this.form);
    }
    renderCore() {
        this.form = this.renderForm();
        return this.form;
    }
    renderForm(data) {
        if (!data) {
            data = this.InitialForm;
        }
        const { submitted, firstNameError, lastNameError, phoneError, emailError, } = this.validation;
        return (createElement("div", { className: "container" },
            createElement("div", { className: "content-wrapper" },
                createElement("div", { className: "content" },
                    createElement("div", { className: "cmp-text" },
                        createElement("h3", { className: "nni-hcp--text--headline3 nni-hcp--alignment--center nni-hcp--bottom-margin--2x" },
                            "Please provide the following information so that",
                            " ",
                            titleCase(this.Rep.firstName),
                            " can connect with you:"),
                        createElement("p", { className: "nni-hcp--text--body-3 nni-hcp--alignment--right nni-hcp--bottom-margin--0x" }, "All fields are required"),
                        createElement("form", { className: [submitted && "submitted"]
                                .filter((x) => x)
                                .join(" "), onSubmit: this.onSubmit.bind(this) },
                            createElement("div", { className: ["field", firstNameError && "error"]
                                    .filter((x) => x)
                                    .join(" ") },
                                createElement("label", { className: "nni-hcp--text--body-1" }, "First Name"),
                                createElement("input", { name: "firstName", value: data === null || data === void 0 ? void 0 : data.FirstName }),
                                createElement("div", { className: "error-message" }, "Please provide your first name")),
                            createElement("div", { className: ["field", lastNameError && "error"]
                                    .filter((x) => x)
                                    .join(" ") },
                                createElement("label", { className: "nni-hcp--text--body-1" }, "Last Name"),
                                createElement("input", { name: "lastName", value: data === null || data === void 0 ? void 0 : data.LastName }),
                                createElement("div", { className: "error-message" }, "Please provide your last name")),
                            createElement("div", { className: ["field", emailError && "error"]
                                    .filter((x) => x)
                                    .join(" ") },
                                createElement("label", { className: "nni-hcp--text--body-1" }, "Email Address"),
                                createElement("input", { name: "email", value: data === null || data === void 0 ? void 0 : data.Email }),
                                createElement("div", { className: "error-message" }, "Please provide your email address")),
                            createElement("div", { className: ["field", phoneError && "error"]
                                    .filter((x) => x)
                                    .join(" ") },
                                createElement("label", { className: "nni-hcp--text--body-1" }, "Phone Number"),
                                createElement("input", { name: "phone", value: data === null || data === void 0 ? void 0 : data.Phone }),
                                createElement("div", { className: "error-message" }, "Please provide phone number")),
                            createElement("p", { className: "nni-hcp--top-padding--2x nni-hcp--bottom-padding--2x" }, this.LegalText || this.defaultLegalText),
                            createElement("div", { className: "buttons" },
                                createElement("button", { className: "nni-hcp--button nni-hcp--button-style--default" },
                                    createElement("div", null,
                                        createElement("a", { "data-gtm-trigger": "click", "data-gtm-category": "", "data-gtm-action": "", "data-gtm-label": "", "data-gtm-language": "en" },
                                            createElement("span", { className: "button-text" }, "Contact Me"))))))),
                    createElement("a", { href: "#", className: "close", onClick: this.onCancel.bind(this) })))));
    }
}
