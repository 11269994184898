/** @jsx createElement */
import { createElement } from "common/jsx-factory";
export default function ContactButton(props) {
    if (!props.rep) {
        return;
    }
    const onClick = (e) => {
        e.preventDefault();
        props.showModal(props.rep);
    };
    return (createElement("div", { className: "cmp-far__contact-button nni-hcp--button nni-hcp--button-style--default nni-hcp--button-size--small nni-hcp--alignment--left" },
        createElement("div", null,
            createElement("a", { href: "#", onClick: onClick },
                createElement("span", { className: "button-text" }, "Contact now")))));
}
