import emailRegex from "email-regex";
import { cleanPhone, cleanZip } from "./formatting";
export function validateEmail(email) {
    return emailRegex({ exact: true }).test(email);
}
export function validatePhone(phone) {
    if (!phone) {
        return false;
    }
    const clean = cleanPhone(phone);
    if (!clean || clean.length !== 10) {
        return false;
    }
    return true;
}
const PATTERN_ZIP = /^[0-9]{5}$/;
const PATTERN_ZIP_PLUS_FOUR = /^[0-9]{5}-[0-9]{4}$/;
export function validateZip(zip) {
    if (!zip) {
        return false;
    }
    const clean = cleanZip(zip);
    const isZip = !!clean.match(PATTERN_ZIP);
    const isZipPlusFour = !!clean.match(PATTERN_ZIP_PLUS_FOUR);
    return isZip || isZipPlusFour;
}
