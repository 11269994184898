var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { ease } from "common/util/bezier";
import Tween from "common/tween";
function getFrame() {
    return __awaiter(this, void 0, void 0, function* () {
        return new Promise((resolve) => window.requestAnimationFrame(resolve));
    });
}
export default class Animation {
    constructor(element, property, endValue, duration = 500, bezier = ease) {
        this.duration = duration;
        this.bezier = bezier;
        this.tween = new Tween(element, property, endValue);
    }
    play() {
        return __awaiter(this, void 0, void 0, function* () {
            const start = yield getFrame();
            for (let timestamp = start; timestamp - start < this.duration; timestamp = yield getFrame()) {
                const percent = Math.min((timestamp - start) / this.duration, 1);
                this.tween.seek(percent, this.bezier);
            }
        });
    }
}
