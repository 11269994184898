export function geoZip() {
    return new Promise((resolve) => {
        if (window.geoip2 === undefined) {
            return resolve(undefined);
        }
        window.geoip2.city((location) => {
            return resolve(location.postal.code);
        }, () => {
            return resolve(undefined);
        });
    });
}
