/** @jsx createElement */
import { createElement } from "common/jsx-factory";
export default function ZipForm(props) {
    let value = props.zip;
    const onChange = (e) => {
        value = e.target.value;
    };
    return (createElement("form", { className: "cmp-far__zip cmp-far__zip-form cmp-text", onSubmit: (e) => {
            e.preventDefault();
            props.onApply(value);
        } },
        createElement("p", { className: "nni-hcp--text--body-3" },
            "Your zip code is",
            " ",
            createElement("input", { type: "text", name: "Zip", value: props.zip, autoFocus: true, onKeyUp: onChange }),
            createElement("a", { href: "#", className: "nni-hcp--text--body-4", onClick: (e) => {
                    e.preventDefault();
                    props.onApply(value);
                } }, "Apply"))));
}
