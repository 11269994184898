import * as Bezier from "common/util/bezier";
class Tween {
    constructor(object, property, finalValue) {
        if (!(object !== undefined &&
            property !== undefined &&
            finalValue !== undefined)) {
            throw new Error("Invalid argument passed to Tween class!");
        }
        this.object = Object.freeze(object);
        this.property = Object.freeze(property);
        this.finalValue = Object.freeze(finalValue);
        this.initialValue = Object.freeze(this.object[this.property === "number" ? this.property.toString() : this.property]);
        this.formatValue = Object.freeze(typeof this.finalValue === "string" &&
            this.finalValue.replace(numberRegex, formatPlaceholder));
        this.parsedFinalValues = Object.freeze(parseFloats(this.finalValue));
        this.parsedInitialValues = Object.freeze(parseAndFill(this.initialValue.toString(), this.parsedFinalValues.length));
    }
    seek(percent, easeFunction = Bezier.ease) {
        this.object[this.property] = this.valueFor(percent, easeFunction);
    }
    isNoop() {
        return this.parsedInitialValues.every((value, i) => this.parsedFinalValues[i] === value);
    }
    valueFor(percent, easeFunction) {
        const newValues = this.parsedInitialValues.map((value, i) => {
            const finalValue = this.parsedFinalValues.length > i ? this.parsedFinalValues[i] : value;
            return (value + (finalValue - value) * easeFunction(percent)).toString();
        });
        return this.formatValue
            ? formattedValue(this.formatValue, newValues)
            : parseInt(newValues[0]);
    }
}
export default Tween;
const numberRegex = /-?\d+\.?\d*/g;
const formatPlaceholder = ";"; // Needs to be something that wouldn't normally appear in a CSS value
const parseFloats = (obj) => {
    if (typeof obj === "string") {
        const matches = obj.match(numberRegex);
        return matches === null
            ? []
            : matches.map((match) => Math.round(parseFloat(match) * 100) / 100);
    }
    else if (typeof obj === "number") {
        return [obj];
    }
    return [];
};
const formattedValue = (fmt, values) => {
    let i = 0;
    return fmt.replace(new RegExp(formatPlaceholder, "g"), () => {
        const index = i;
        i += 1;
        return values[index];
    });
};
const parseAndFill = (value, length) => {
    const arr = parseFloats(value);
    while (arr.length < length) {
        arr.push(0);
    }
    return arr;
};
