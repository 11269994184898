export const PI = 3.1415926535;
// timing functions taken from https://easings.net/
export const linear = (x) => x;
export const easeInSine = (x) => 1 - Math.cos((x * PI) / 2);
export const easeOutSine = (x) => Math.sin((x * PI) / 2);
export const easeInOutSine = (x) => (-1 * (Math.cos(PI * x) - 1)) / 2;
// Convenience aliases
export const easeIn = easeInSine;
export const easeOut = easeOutSine;
export const easeInOut = easeInOutSine;
export const ease = easeInOut;
export function createEaseInBezier(power) {
    return (x) => Math.pow(x, power);
}
export function createEaseOutBezier(power) {
    return (x) => 1 - Math.pow(1 - x, power);
}
export function createEaseInOutBezier(power) {
    return (x) => x < 0.5
        ? Math.pow(2, power - 1) * Math.pow(x, power)
        : 1 - Math.pow(-2 * x + 2, power) / 2;
}
export const easeInQuad = createEaseInBezier(2);
export const easeOutQuad = createEaseOutBezier(2);
export const easeInOutQuad = createEaseInOutBezier(2);
export const easeInCubic = createEaseInBezier(3);
export const easeOutCubic = createEaseOutBezier(3);
export const easeInOutCubic = createEaseInOutBezier(3);
export const easeInQuart = createEaseInBezier(4);
export const easeOutQuart = createEaseOutBezier(4);
export const easeInOutQuart = createEaseInOutBezier(4);
export const easeInQuint = createEaseInBezier(5);
export const easeOutQuint = createEaseOutBezier(5);
export const easeInOutQuint = createEaseInOutBezier(5);
