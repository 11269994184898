/** @jsx createElement */
import { createElement } from "common/jsx-factory";
import { titleCase } from "../../helpers";
const SALES_FORCE_TITLE_MAP = {
    HTM: "Hemophilia Therapy Manager",
    HIAM: "Hemophilia Institutional Account Manager",
    GDTM: "Growth Disorder Territory Manager",
    FRM: "Field Reimbursement Manager",
    RRTS: "Rare Renal Therapy Specialist",
};
export default function Heading(props) {
    function title(salesForce) {
        if (!salesForce || !SALES_FORCE_TITLE_MAP[salesForce]) {
            return "";
        }
        return SALES_FORCE_TITLE_MAP[salesForce];
    }
    if (!props.rep) {
        return;
    }
    return (createElement("div", { className: "cmp-far__heading cmp-text" },
        createElement("div", null,
            window.NovoMedLink.Settings.brand.displayName && (createElement("p", { className: "nni-hcp--text--subhead" },
                "Talk to us about",
                " ",
                createElement("span", { dangerouslySetInnerHTML: window.NovoMedLink.Settings.brand.displayName }))),
            props.rep.salesForce && (createElement("p", { className: "nni-hcp--text--body-2" },
                "Your ",
                title(props.rep.salesForce),
                " is",
                " ",
                createElement("i", null,
                    titleCase(props.rep.firstName),
                    " ",
                    titleCase(props.rep.lastName)))))));
}
