var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
export function getScrollOffset(element, ancestor = document.documentElement) {
    if (!element.offsetParent) {
        return 0;
    }
    let top = 0;
    while (element !== document.body &&
        element !== ancestor &&
        !element.contains(ancestor) &&
        element.offsetParent instanceof HTMLElement) {
        top += element.offsetTop;
        element = element.offsetParent;
    }
    return element !== ancestor && element.contains(ancestor)
        ? top - ancestor.offsetTop
        : top;
}
export function sleep(duration) {
    return __awaiter(this, void 0, void 0, function* () {
        return new Promise((resolve) => setTimeout(resolve, duration));
    });
}
export function makeAbsoluteUrl(url) {
    return url.includes("://")
        ? url
        : window.location.origin + (url[0] === "/" ? url : "/" + url);
}
// Resolves a relative path to either a site in the JCR (if called on author)
// or relative to the current domain (if on publish)
export function resolveRelativePath(path) {
    if (path[0] !== "/") {
        throw "Relative path must start with '/'";
    }
    const pathParts = window.location.pathname.split("/");
    // Minimum JCR path length = 5 (including the empty string before the first slash):
    // /content/site/lang/[path]
    return pathParts.length > 4 && pathParts[1] === "content"
        ? "/content/" + pathParts[2] + "/" + pathParts[3] + path
        : path;
}
