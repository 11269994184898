/** @jsx createElement */
import { createElement } from "common/jsx-factory";
export function Connect(connect) {
    return (createElement("div", { className: "connect cmp-text" },
        createElement("p", { className: "nni-hcp--text--body-xl nni-hcp--alignment--center" }, "Connect with a Novo Nordisk representative in your area:"),
        createElement("div", { className: "icon icon--connect-pin" }),
        createElement("p", { className: "nni-hcp--text--body-xl" }, "Your Zip Code:"),
        createElement("p", { className: "connect__zip nni-hcp--text--headline2" }, connect.Zip),
        createElement("a", { href: "#", className: "nni-hcp--top-margin--2x", onClick: (e) => {
                e.preventDefault();
                connect.onChange();
            } }, "Change")));
}
